import React from "react"
import styled from 'styled-components'
import {Container} from 'reactstrap'

import Layout from "components/Layout/Layout"
import HeaderCopy from "components/shared/HeaderCopy"

const Content = styled.div`
    text-align: center;
    margin: 2rem auto;
    max-width: 540px;
`
const FeedbackPage = () => {
    return (
        <Layout slug="thank-you" title="Thank you">
            <HeaderCopy title="Thank you"/>

            <Container fluid={true}>
                <Container>
                    <Content>
                        <h2>Your form was successfully submitted</h2>
                    </Content>
                </Container>
            </Container>
        </Layout>
    )
}

export default FeedbackPage